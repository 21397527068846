<template>
  <v-flex xs12 sm10 md4>
    <v-card v-if="!isAuthenticated" class="elevation-12">
      <v-card-title class="primary align-self-center">
        <h2 class="headline ml-2 white--text">User Login</h2>
      </v-card-title>
      <v-card-text class="pa-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            prepend-inner-icon="mdi-email"
            name="email"
            label="Email"
            type="email"
            v-model="email"
            :rules="emailRules"
            hint="Enter your registered email address"
            autofocus
            dense
            outlined
          ></v-text-field>
          <v-text-field
            prepend-inner-icon="mdi-account-key"
            label="Password"
            :type="showpwd ? 'text' : 'password'"
            v-model="password"
            :rules="passwordRules"
            dense
            outlined
            :append-icon="showpwd ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showpwd = !showpwd"
            @keyup.enter.native="login"
          ></v-text-field>
          <div class="text-center">
            <div>
              Do not have an account?&nbsp;
              <router-link to="/register">Register</router-link>
            </div>
            <div>
              <router-link to="/resetpassword">Forgot Password?</router-link>
            </div>
          </div>
        </v-form>
      </v-card-text>
      <div class="text-center pb-10">
        <v-btn color="primary white--text" class="mr-2" to="/">Cancel</v-btn>
        <v-btn
          color="primary white--text"
          :loading="loading"
          :disabled="!valid"
          @click="login"
          >Sign In</v-btn
        >
      </div>
    </v-card>

    <v-card v-if="isAuthenticated" class="elevation-12">
      <v-card-title class="primary justify-center">
        <h2 class="headline ml-4 white--text">Verification Code</h2>
      </v-card-title>
      <v-card-text class="pt-10 px-10">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-otp-input
            autofocus
            length="6"
            v-model="otpcode"
            type="number"
          ></v-otp-input>
        </v-form>
      </v-card-text>
      <div class="text-center pb-10">
        <v-btn color="primary white--text" :loading="loading" @click="verifyOtp"
          >Verify</v-btn
        >
      </div>
    </v-card>
  </v-flex>
</template>

<script>
import AuthLayout from "@/layouts/AuthLayout.vue";
import api from "../services/axiosconfig";
import { auth, db } from "../services/firebase";
import { doc, getDoc } from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  browserSessionPersistence,
  sendEmailVerification,
} from "@firebase/auth";

export default {
  name: "login",
  data() {
    return {
      loading: false,
      valid: true,
      password: "",
      showpwd: false,
      isAuthenticated: false,
      otpcode: "",
      token: "",
      secret: "",
      ledgerkey: "",
      username: "",
      address1: "",
      address2: "",
      address3: "",
      pincode: "",
      restriction: {},
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 6) || "Password must be less than 6 characters",
      ],
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },

  created() {
    this.$emit(`update:layout`, AuthLayout);
  },

  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        setPersistence(auth, browserSessionPersistence)
          .then(() => {
            signInWithEmailAndPassword(auth, this.email, this.password)
              .then((userCredentials) => {
                if (userCredentials.user.emailVerified) {
                  getDoc(doc(db, "users", userCredentials.user.uid))
                    .then((snapshot) => {
                      if (snapshot.exists()) {
                        this.ledgerkey = snapshot.data().ledgerkey;
                        this.username = snapshot.data().name;
                        this.address1 = snapshot.data().address1;
                        this.address2 = snapshot.data().address2;
                        this.address3 = snapshot.data().address3;
                        this.pincode = snapshot.data().pincode;
                        this.secret = snapshot.data().userotp;
                        this.restriction = snapshot.data().restriction;
                        api
                          .post("/aejmis/api/totp/generate", {
                            secret: this.secret,
                          })
                          .then((response) => {
                            this.token = response.data.token;
                            const mailOptions = {
                              name: userCredentials.user.displayName,
                              email: userCredentials.user.email,
                              message: `Verification code is ${this.token}`,
                            };
                            api
                              .post("/sendOTPMail", mailOptions)
                              .then((info) => {
                                this.$store.dispatch(
                                  "setUser",
                                  userCredentials.user
                                );
                                this.$toasted.global.optimus_info({
                                  message:
                                    "A verification code has been sent to your registered email address.",
                                });
                                console.log(info);
                                this.isAuthenticated = true;
                                this.loading = false;
                              })
                              .catch(() => {
                                this.clearState();
                                this.$toasted.global.optimus_error({
                                  message:
                                    "Error sending verification code to your registered email address.",
                                });
                              });
                          })
                          .catch((error) => {
                            console.log(error);
                            this.clearState();
                            this.$toasted.global.optimus_error({
                              message:
                                "Could not generate verification token. Something went wrong.",
                            });
                          });
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                      this.clearState();
                      this.$toasted.global.optimus_info({
                        message:
                          "Your account has not been setup. Contact administrator",
                      });
                    });
                } else {
                  sendEmailVerification(auth.currentUser)
                    .then(() => {
                      this.$toasted.global.optimus_info({
                        message:
                          "A verification email link has been sent to your registered email address.",
                      });
                      this.clearState();
                    })
                    .catch((error) => {
                      console.log(error);
                      this.clearState();
                      this.$toasted.global.optimus_error({
                        message: `Could not send verification email link to your registered email address.`,
                      });
                    });
                }
              })
              .catch((error) => {
                console.log(error);
                this.clearState();
                this.$toasted.global.optimus_error({
                  message: `Could not sign in to your account. Something went wrong`,
                });
              });
          })
          .catch((error) => {
            console.log(error);
            this.clearState();
            this.$toasted.global.optimus_error({
              message: `Could not sign in to your account. Something went wrong`,
            });
          });
      }
    },

    verifyOtp() {
      this.loading = true;
      api
        .post("/aejmis/api/totp/validate", {
          secret: this.secret,
          token: this.otpcode,
        })
        .then((response) => {
          if (response.data.valid) {
            localStorage.setItem("TOTP_USER", this.secret);
            localStorage.setItem("TOTP_TOKEN", this.token);
            localStorage.setItem("LEDG_KEY", this.ledgerkey);
            localStorage.setItem("USER_NAME", this.username);
            localStorage.setItem("ADDRESS_1", this.address1);
            localStorage.setItem("ADDRESS_2", this.address2);
            localStorage.setItem("ADDRESS_3", this.address3);
            localStorage.setItem("PIN_CODE", this.pincode);
            localStorage.setItem("PERMITTED", this.restriction.value);

            this.$router.replace("/dashboard");
            this.loading = false;
          } else {
            this.clearState();
            this.$toasted.global.optimus_error({
              message: "OTP entered is not valid or has expired. Try again.",
            });
          }
        })
        .catch((error) => {
          this.clearState();
          console.log(error);
          this.$toasted.global.optimus_error({
            message: "Could not verify the OTP. Something went wrong.",
          });
        });
    },

    clearState() {
      signOut(auth);
      this.isAuthenticated = false;
      this.loading = false;
      this.$store.dispatch("setUser", null);
      localStorage.removeItem("TOTP_USER");
      localStorage.removeItem("TOTP_TOKEN");
      localStorage.removeItem("LEDG_KEY");
      localStorage.removeItem("USER_NAME");
      localStorage.removeItem("ADDRESS_1");
      localStorage.removeItem("ADDRESS_2");
      localStorage.removeItem("ADDRESS_3");
      localStorage.removeItem("PIN_CODE");
      localStorage.removeItem("PERMITTED");

      this.$router.push("/");
    },
  },
};
</script>
